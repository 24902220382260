import { graphql, useStaticQuery } from "gatsby"

export const useMembers = lang => {
  const members = useStaticQuery(
    graphql`
      query {
        allStrapiMembers {
          edges {
            node {
              id
              Name
              posicion
              position
              Image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  if (lang === "en") {
    members.allStrapiMembers.edges.forEach(function (item, index) {
      item.node.position = item.node.position
    })
  } else {
    members.allStrapiMembers.edges.forEach(function (item, index) {
      item.node.position = item.node.posicion
    })
  }

  return members.allStrapiMembers.edges
}
