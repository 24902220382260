import { css } from "styled-components"
import React from "react"
import { TeamCardContainer } from "../styles/components/TeamCard"
import * as ImageComponent from "./Image"
import Img from "gatsby-image"
import { colorPrincipal } from "../styles/variables"

export default function TeamCard({ Image, Name, position }) {
  console.log(Image)
  return (
    <TeamCardContainer>
      <figure
        css={css`
          border: 1px solid ${colorPrincipal};
          /* @media (min-width: 500px) {
            & .gatsby-image-wrapper {
              width: 300px !important;
              height: 400px !important;
            }
          }
          @media (max-width: 500px) {
            & .gatsby-image-wrapper {
              width: 400px !important;
            }
          } */
        `}
      >
        <Img fluid={Image.localFile.childImageSharp.fluid} />
      </figure>
      <h3>{Name}</h3>
      <h4>{position}</h4>
    </TeamCardContainer>
  )
}
