import styled from 'styled-components';
import { colorPrincipal, fuentePrincipal } from '../variables';

export const TeamCardContainer = styled.div`
  display: flex;
  flex: 0 1 calc(33% - 6em);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  
  & figure{
    width: 100%;
    margin-bottom: 1em;
    border-radius: 8px;
    overflow: hidden;
    height: 400px;
    @media (max-width: 500px){
      height: 300px;
    }
  }

  & h3{
      
      color: ${colorPrincipal};
      margin: 0;
      font-size: 1.5rem;
  }

  & h4{
      margin: 0;
      font-family: ${fuentePrincipal};
      font-weight: normal;
      font-size: 1.3rem;
  }

  @media (max-width:500px){
    flex: 0 1 calc(100% - 2em);
  }
`;