import * as React from "react"

import Seo from "../components/seo"
import Hero from "../components/Hero"
import { Button, Container } from "../styles"
import TeamCard from "../components/TeamCard"
import { TeamContainer } from "../styles/pages/about"
import styled, { css } from "styled-components"
import { useTranslate } from "react-translate"
import ReactHtmlParser from "react-html-parser"
import marked from "marked"

const Description = styled.p`
  font-size: 1.4rem;

  @media (max-width: 500px) {
    font-size: 1.2rem;
  }
`

const AboutView = ({ info, members, buttonText }) => {
  const aboutText = info.Cuerpo[0]

  const aboutTexts = useTranslate("aboutPage")

  return (
    <>
      <Seo title="About" />
      <Hero img={"team.png"} title={aboutText.Title} />
      <section>
        <Container>
          <Description>
            {ReactHtmlParser(marked(aboutText.Description))}{" "}
          </Description>
        </Container>
      </section>
      <section>
        <Container
          css={css`
            @media (max-width: 500px) {
              padding-top: 0;
            }
          `}
        >
          <h2>{ReactHtmlParser(marked(aboutText.TitleTeam))}</h2>
          <TeamContainer>
            {members.map(({ node }, index) => (
              <TeamCard key={index} {...node} />
            ))}
          </TeamContainer>

          <Button big={true} to="/contact">
            {buttonText}
          </Button>
        </Container>
      </section>
    </>
  )
}

export default AboutView
