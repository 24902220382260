import styled from "styled-components"
import { colorOscuro } from "../variables"

export const TeamContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6em;
  justify-content: center;
  position: relative;
  &:after,
  &:before {
    content: "";
    position: absolute;
    width: 25px;
    height: 280px;
    background: ${colorOscuro};
  }
  &:after {
    top: 8em;
    left: 0;
  }

  &:before {
    top: calc(10em + 280px);
    right: 0;
  }

  & > div:nth-of-type(3n + 2) {
    margin-top: -6em;
  }

  @media (max-width: 500px) {
    & > div:nth-of-type(3n + 2) {
      margin-top: 0;
    }
    &:after,
    &:before {
      width: 20px;
    }

    &:after {
      left: -2em;
    }
    &:before {
      right: -2em;
    }
  }
`
